import { ClientApi } from '@serenityapp/api-client-graph';
import { SelectorItemProps } from '@serenityapp/components-react-common';

type Edges = ClientApi.Filter.Fragments.ConversationEdge[];

/**
 * Filters an array of filter's conversation objects by a given subsubtype.
 *
 * @param {Edges | []} edges - The array of objects to filter.
 * @param {string} subsubtype - The subsubtype to filter by.
 * @returns {SelectorItemProps[]} - An array of objects with 'id' and 'label' properties.
 */
export const filterChannelsBySubtype = (
  edges: Edges,
  subsubtype: string,
): SelectorItemProps[] => {
  if (edges.length === 0) {
    return [];
  }
  return edges
    .filter((edge) => edge.node.subsubtype === subsubtype)
    .map((edge) => ({
      id: edge.node.id,
      label: edge.node.name || '',
    }));
};
