import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from 'urql';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';

import { snackAdd } from '@serenityapp/redux-store';
import { useMakeTestId } from '@serenityapp/components-react-web';
import { ClientApi } from '@serenityapp/api-client-graph';
import { useFilterDetail } from '../hooks';

type ArchiveFilterDialogProps = {
  filterId: string;
  dataTestId?: string;
  isOpen?: boolean;
  onClose: () => void;
  onActionSuccess?: () => void;
};

const ArchiveFilterDialog = ({
  dataTestId,
  isOpen = true,
  filterId,
  onClose,
  onActionSuccess,
}: ArchiveFilterDialogProps) => {
  const makeTestId = useMakeTestId('ArchiveFilterDialog', dataTestId);
  const archiveFilterDialog = makeTestId('');

  const dispatch = useDispatch();

  const { filter } = useFilterDetail(filterId);

  const [isArchiving, setIsArchiving] = useState(false);

  const [, archiveFilter] = useMutation<
    ClientApi.Filter.Api.Archive.MutationResult,
    ClientApi.Filter.Api.Archive.Variables
  >(ClientApi.Filter.Api.Archive.Mutation);

  const handleConfirmClick = () => {
    setIsArchiving(true);

    archiveFilter({
      input: {
        id: filterId,
      },
    }).then((result) => {
      setIsArchiving(false);
      if (result.error || result.data?.result?.success === false) {
        dispatch(snackAdd({ message: 'Error archiving filter', type: 'error' }));
      } else {
        dispatch(snackAdd({ message: 'Filter successfully archived', type: 'success' }));
        onActionSuccess?.();
      }
    });
  };

  return (
    <Dialog fullWidth data-testid={archiveFilterDialog} open={isOpen} onClose={onClose}>
      <DialogTitle>Archive filter</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to Archive {filter?.name}?</Typography>
        <List dense sx={listSx}>
          <ListItem sx={listItemSx}>
            Archiving will remove {filter?.name} from the filters list.
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button data-testid={makeTestId('cancel')} disabled={isArchiving} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          data-testid={makeTestId('confirm')}
          loading={isArchiving}
          variant="contained"
          onClick={handleConfirmClick}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const listSx = {
  listStyle: 'inside',
};

const listItemSx = {
  display: 'list-item',
};

export default ArchiveFilterDialog;
