import { useCallback, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridRowProps,
} from '@mui/x-data-grid-pro';
import { Add } from '@mui/icons-material';
import { Box, Button } from '@mui/material';

import { AdministrationHeader, useMakeTestId, View } from '@serenityapp/components-react-web';

import { useAdminBreadcrumbs } from '../hooks';
import { AdminDataGrid } from '../components';
import { useFilters } from './hooks';
import { ActionsCell, CustomGridRow, QuickSearchToolbarFilters } from './components';

export const DEFAULT_DEVICES_PAGE_SIZE = 25;
export const PAGE_SIZE_OPTIONS = [DEFAULT_DEVICES_PAGE_SIZE, 50, 100];

const FiltersPage = () => {
  const title = 'Filters categories';
  const makeTestId = useMakeTestId('FiltersPage');
  const mainTestId = makeTestId('');

  const { filters, isFetching } = useFilters();

  const navigate = useNavigate();

  const crumbs = useAdminBreadcrumbs(title);

  const goToResourceDetails = (params: GridRowParams) => {
    const { id } = params;
    navigate(id.toString());
  };

  const goToResourceCreate = () => navigate('create');

  const addButton = (
    <Button
      key="add-button"
      data-testid={makeTestId('add')}
      startIcon={<Add />}
      variant="contained"
      onClick={goToResourceCreate}
    >
      New
      <Box component="span" sx={longButtonTitleSx}>
        &nbsp;filter
      </Box>
    </Button>
  );

  const actionsCellRenderer = useCallback(
    (params: GridRenderCellParams) => <ActionsCell params={params} makeTestId={makeTestId} />,
    [makeTestId],
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        sortable: true,
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
        sortable: false,
      },
      {
        field: 'id',
        headerName: 'Actions',
        width: 80,
        disableColumnMenu: true,
        disableColumnSelector: true,
        sortable: false,
        renderCell: actionsCellRenderer,
        filterable: false,
        getApplyQuickFilterFn: () => null,
      },
    ],
    [actionsCellRenderer],
  );

  return (
    <View data-testid={mainTestId}>
      <View.Header>
        <AdministrationHeader actions={[addButton]} crumbs={crumbs} title={title} />
      </View.Header>
      <View.Content>
        <AdminDataGrid
          disableColumnSelector
          disableRowSelectionOnClick
          ignoreDiacritics
          disableColumnMenu
          initialState={{
            pagination: { paginationModel: { pageSize: DEFAULT_DEVICES_PAGE_SIZE } },
          }}
          loading={isFetching && !filters.length}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          onRowClick={goToResourceDetails}
          pagination
          columns={columns}
          rows={filters}
          slots={{
            toolbar: QuickSearchToolbarFilters,
            row: (props: GridRowProps) => <CustomGridRow {...props} makeTestId={makeTestId} />,
          }}
        />
        <Outlet />
      </View.Content>
    </View>
  );
};

const longButtonTitleSx = {
  display: {
    xs: 'none',
    sm: 'block',
  },
};

export default FiltersPage;
