import {
  configureStore as rtkConfigureStore,
  combineReducers,
  EnhancedStore,
} from '@reduxjs/toolkit';
import LocalForage from 'localforage';
import createSagaMiddleware, { EventChannel } from 'redux-saga';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import {
  createTransform,
  PersistConfig as ReduxPersistConfig,
  persistReducer,
  persistStore,
  MigrationManifest,
} from 'redux-persist';
import * as R from 'ramda';
import { HubCapsule } from '@aws-amplify/core';

import * as Sentry from '@sentry/react';

import { IAnalytics, ISentry, serializeError } from '@serenityapp/core';
import { IAwsConfig } from '@serenityapp/core-aws';
import { IAuthUser } from '@serenityapp/core-cognito';

import rootSaga from './sagas';
import {
  RootState,
  SagaContext,
  sessionRootSaga,
  conversationsRootSaga,
  locationRootSaga,
  snackbarReducer,
  sessionReducer,
  viewerReducer,
  usersReducer,
  viewerRootSaga,
  viewerInitialState,
  rehydrateAppsState,
  rehydrateViewerState,
  rehydrateSessionState,
  rehydrateSnackbarState,
  ViewerState,
  rehydrateUsersState,
  usersRootSaga,
  emailAddressReducer,
  emailAddressInitialState,
  conversationsReducer,
  locationsReducer,
  rehydrateConversationsState,
  adminRootSaga,
  rehydrateIAMGroupsState,
  iamGroupRootSaga,
  groupsReducer,
  invitationIntialState,
  invitationReducer,
  appsInitialState,
  appsReducer,
  organizationsReducer,
  organizationsInitialState,
  organizationsRootSaga,
  messagesReducer,
  messagesRootSaga,
  rehydrateLocationsState,
  OrganizationsState,
  rehydrateOrganizationsState,
  localFilesReducer,
  localFilesRootSaga,
  createSagaErrorLogger,
  rootSaga as commonRootSaga,
  subscriptionsRootSaga,
  rehydrateMessagesState,
  viewerInitSuccess,
  createLoggingMiddleware,
  usersForDMReducer,
  usersForDMInitialState,
  usersForDMRootSaga,
  UsersForDMState,
  rehydrateUsersForDMState,
  rehydrateViewerMemberOfConversationsState,
  ViewerMemberOfConversationsState,
  viewerMemberOfConversationsInitialState,
  viewerMemberOfConversationsReducer,
  viewerMemberOfConversationsRootSaga,
  appsRootSaga,
  AppsState,
  messagesCacheRootSaga,
  messagesCacheInitialState,
  messagesCacheReducer,
  rehydrateMessagesCacheState,
  MessagesCacheState,
  messageEntryReducer,
  messageEntryInitialState,
  MessageEntryState,
  rehydrateMessageEntryState,
  googleCalendarsReducer,
  rehydrateGoogleCalendarsState,
  GoogleCalendarsState,
  googleCalendarsRootSaga,
  googleCalendarsInitialState,
  rehydrateNormalizedApiState,
  NormalizedApiState,
  normalizedApiReducer,
  normalizedApiInitialState,
  normalizedApiRootSaga,
  createUpdateByCreatedCacheEntryNoop,
  authEvent,
  sessionInitReady,
  slashCommandsRootSaga,
  slashCommandsReducer,
  slashCommandsInitialState,
  rehydrateSlashCommandsState,
  SlashCommandsState,
  slashCommandRequestRootSaga,
  slashCommandRequestReducer,
  slashCommandRequestInitialState,
  rehydrateSlashCommandRequestState,
  SlashCommandRequestState,
  viewerSignOut,
  viewerShowSignOutReason,
  notificationResponseReducer,
  notificationResponseInitialState,
  NotificationResponseState,
  rehydrateLastNotificationResponseState,
  viewerSignIn,
} from '@serenityapp/redux-store';
import { IApiClient } from '@serenityapp/core-graphql';
import createMigrate from 'redux-persist/es/createMigrate';
import { FileSystemWeb } from '../utils/file-system-web';
import { netInfoApi } from '../utils/net-info-api';
import { IStorageOutbox, IStorageProvider } from '@serenityapp/core-storage';
import { ReduxPersistStorage } from './redux-persist-storage';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { put, take, takeEvery } from 'redux-saga/effects';
import { ForbiddenErrorEvent } from '../App';
import { toForbiddenErrorMessage } from '@serenityapp/domain';

// Configure browser storage
LocalForage.config({
  name: 'serenity/store',
  // Storage backend used in order of priority (LocalStorage has strict size limits - prefer IndexedDB/WebSQL)
  driver: [LocalForage.INDEXEDDB, LocalForage.WEBSQL, LocalForage.LOCALSTORAGE],
});

// `deserialize` is not documented, but required for redux-persist to work when skipping serialization.
// See https://github.com/rt2zz/redux-persist/blob/master/src/getStoredState.ts#L16
type PersistConfig = ReduxPersistConfig<RootState, any, any, any> & {
  deserialize?: boolean | ((persistedState: any) => any);
};

const initialState = {
  viewer: viewerInitialState,
  invitation: invitationIntialState,
  apps: appsInitialState,
  emailAddress: emailAddressInitialState,
  organizations: organizationsInitialState,
  usersForDM: usersForDMInitialState,
  googleCalendars: googleCalendarsInitialState,
  viewerMemberOfConversations: viewerMemberOfConversationsInitialState,
  messagesCache: messagesCacheInitialState,
  normalizedApi: normalizedApiInitialState,
  stateByUserId: {},
  messageEntry: messageEntryInitialState,
  slashCommands: slashCommandsInitialState,
  slashCommandRequest: slashCommandRequestInitialState,
  notificationResponse: notificationResponseInitialState,
};

const migrations: MigrationManifest = {
  10: (state: any) => {
    return { ...initialState, _persist: state?._persist };
  },
};

const getRootReducer = () => {
  return (state: RootState = initialState, action: any): RootState => {
    const emailAddress = emailAddressReducer(state.emailAddress, action);
    const apps = appsReducer(state.apps, action);
    const invitation = invitationReducer(state.invitation, action);
    const viewer = viewerReducer(state.viewer, action);
    const organizations = organizationsReducer(state.organizations, action);
    const messageEntry = messageEntryReducer(state.messageEntry, action);
    const googleCalendars = googleCalendarsReducer(state.googleCalendars, action);
    const usersForDM = usersForDMReducer(state.usersForDM, action);
    const viewerMemberOfConversations = viewerMemberOfConversationsReducer(
      state.viewerMemberOfConversations,
      action,
    );
    const messagesCache = messagesCacheReducer(state.messagesCache, action);
    const normalizedApi = normalizedApiReducer(state.normalizedApi, action);
    const slashCommands = slashCommandsReducer(state.slashCommands, action);
    const slashCommandRequest = slashCommandRequestReducer(state.slashCommandRequest, action);
    const notificationResponse = notificationResponseReducer(state.notificationResponse, action);

    const viewerId = viewer.id;

    if (action.type === viewerInitSuccess.type) {
      // Drops user-specific states for all other users than the one currently signed in
      // TODO: (Michael) Remove stateByUserId and only keep data for the current user
      state.stateByUserId = R.pick([action.payload.id], state.stateByUserId);
    }

    if (viewerId) {
      return {
        viewer,
        emailAddress,
        apps,
        invitation,
        organizations,
        stateByUserId: {
          ...state.stateByUserId,
          [viewerId]: combineReducers({
            iamGroups: groupsReducer,
            conversations: conversationsReducer,
            localFiles: localFilesReducer,
            locations: locationsReducer,
            messages: messagesReducer,
            session: sessionReducer,
            snackbar: snackbarReducer,
            users: usersReducer,
          })(state.stateByUserId[viewerId], action),
        },
        googleCalendars,
        usersForDM,
        viewerMemberOfConversations,
        messagesCache,
        messageEntry,
        normalizedApi,
        slashCommands,
        slashCommandRequest,
        notificationResponse,
      };
    } else {
      return {
        ...state,
        viewer,
      };
    }
  };
};

/*
  TODO: FIXME: GETRIDOFTHIS: We are creating two stores in development, because we depend on async
  resources to configure the store, and we are doing this in a useEffect call (React runs effects twice in dev)
  This trips up the redux devtools integration.
  We need to drop the dependency on async resources to create the store, and call the async stuff
  inside a saga.
 */
let store: EnhancedStore<RootState & PersistPartial, any, any[]> | null = null;
let stopSaga = () => {
  return;
};

export const configureStore = (
  authUser: IAuthUser | undefined,
  awsConfig: IAwsConfig,
  sentry: ISentry,
  analytics: IAnalytics,
  apiClient: IApiClient,
  publicApiClient: IApiClient,
  storageClient?: IStorageProvider,
  storageOutbox?: IStorageOutbox,
  authEventChannel?: EventChannel<HubCapsule>,
  forbiddenErrorEventChannel?: EventChannel<ForbiddenErrorEvent>,
) => {
  if (store !== null) {
    return {
      store,
      stopSaga,
    };
  }

  function* processAuthEvents() {
    if (!authEventChannel) return;

    // After session is ready, start processing the authEventChannel events
    // and dispatching them as redux actions, to allow reducers to listen to them.
    yield take(sessionInitReady);
    yield takeEvery(authEventChannel, function* (data) {
      yield put(authEvent(data));
    });
  }
  /**
   * This saga watches for forbidden errors and signs out the user if one is received.
   */
  function* watchForbiddenErrors() {
    if (!forbiddenErrorEventChannel) return;
    yield take(sessionInitReady);
    yield takeEvery(forbiddenErrorEventChannel, function* (data) {
      const msg = data.message;
      const message = toForbiddenErrorMessage(msg);
      yield put(viewerShowSignOutReason({ reason: { title: 'Error', message } }));
      yield put(viewerSignOut());
    });
  }

  const sagaMiddleware = createSagaMiddleware({
    onError: (error, { sagaStack }) => {
      sentry.captureException(error, {
        extra: {
          sagaStack,
          serializedError: serializeError(error),
        },
      });
      if (process.env.NODE_ENV === 'development') {
        throw error;
      }
    },
  });

  const loggingMiddleware = createLoggingMiddleware({ analytics, sentry });

  const serialize = (
    inboundState: RootState[keyof RootState],
    _key: keyof RootState,
  ): RootState[keyof RootState] => {
    return inboundState;
  };

  const rehydrate = (
    outboundState: RootState[keyof RootState],
    key: keyof RootState,
  ): RootState[keyof RootState] => {
    switch (key) {
      case 'apps':
        return rehydrateAppsState(outboundState as AppsState);

      case 'organizations':
        return rehydrateOrganizationsState(outboundState as OrganizationsState);

      case 'viewer':
        return rehydrateViewerState(outboundState as ViewerState);

      case 'usersForDM':
        return rehydrateUsersForDMState(outboundState as UsersForDMState);

      case 'messageEntry':
        return rehydrateMessageEntryState(outboundState as MessageEntryState);

      case 'googleCalendars':
        return rehydrateGoogleCalendarsState(outboundState as GoogleCalendarsState);

      case 'viewerMemberOfConversations':
        return rehydrateViewerMemberOfConversationsState(
          outboundState as ViewerMemberOfConversationsState,
        );

      case 'messagesCache':
        return rehydrateMessagesCacheState(outboundState as MessagesCacheState);

      case 'normalizedApi':
        return rehydrateNormalizedApiState(outboundState as NormalizedApiState);

      case 'slashCommands':
        return rehydrateSlashCommandsState(outboundState as SlashCommandsState);

      case 'slashCommandRequest':
        return rehydrateSlashCommandRequestState(outboundState as SlashCommandRequestState);

      case 'stateByUserId':
        return R.mapObjIndexed(
          R.evolve({
            conversations: rehydrateConversationsState,
            messages: rehydrateMessagesState,
            iamGroups: rehydrateIAMGroupsState,
            locations: rehydrateLocationsState,
            session: rehydrateSessionState,
            snackbar: rehydrateSnackbarState,
            users: rehydrateUsersState,
          }),
          outboundState as RootState['stateByUserId'],
        );

      case 'notificationResponse':
        return rehydrateLastNotificationResponseState(outboundState as NotificationResponseState);

      default:
        return outboundState;
    }
  };

  const rootTransform = createTransform(serialize, rehydrate, {
    whitelist: [
      'viewer',
      'stateByUserId',
      'usersForDM',
      'googleCalendars',
      'viewerMemberOfConversations',
      'messagesCache',
      'normalizedApi',
      'messageEntry',
      'slashCommands',
      'slashCommandRequest',
      'notificationResponse',
    ],
  });

  const transforms = () => [rootTransform];

  const persistConfig: PersistConfig = {
    key: 'serenity-redux',
    version: 10,
    migrate: createMigrate(migrations, { debug: false }),
    deserialize: false,
    serialize: false,
    storage: new ReduxPersistStorage(analytics),
    throttle: 5000,
    transforms: transforms(),
    stateReconciler: hardSet,
    writeFailHandler: (error: any) => {
      sentry.captureException(new Error('Error persisting store'), {
        extra: {
          originalError: error,
        },
      });
    },
  };

  // Adds contextual information to Sentry reports
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    attachReduxState: true,
    stateTransformer: (state) => {
      const transformedState = R.pick(['viewerMemberOfConversations'], state);
      return transformedState;
    },
    actionTransformer: (action) => {
      // Do not log payloads that contain sensitive information
      if (action.type.toLowerCase().includes('password') || action.type === viewerSignIn) {
        return {
          ...action,
          payload: undefined,
        };
      }

      return action;
    },
  });

  store = rtkConfigureStore({
    reducer: persistReducer(persistConfig, getRootReducer()),
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(
        sagaMiddleware,
        loggingMiddleware,
      );
    },
    enhancers: (getDefaultEnhancers) => {
      return getDefaultEnhancers().concat(sentryReduxEnhancer);
    },
  });

  const persistor = persistStore(store);

  const sagaErrorLogger = createSagaErrorLogger(analytics, sentry);

  const sagaContext: SagaContext = {
    analytics,
    apiClient,
    authUser,
    awsConfig,
    filesystem: FileSystemWeb,
    netInfoApi,
    logSagaError: sagaErrorLogger,
    sentry,
    persistor,
    publicApiClient,
    storageClient,
    storageOutbox,
    updateMessagesByCreatedCacheEntry: createUpdateByCreatedCacheEntryNoop(sagaErrorLogger),
  };

  const sagas = [
    commonRootSaga,
    adminRootSaga,
    rootSaga,
    subscriptionsRootSaga,
    conversationsRootSaga,
    iamGroupRootSaga,
    appsRootSaga,
    locationRootSaga,
    localFilesRootSaga,
    messagesRootSaga,
    organizationsRootSaga,
    sessionRootSaga,
    usersRootSaga,
    usersForDMRootSaga,
    googleCalendarsRootSaga,
    viewerMemberOfConversationsRootSaga,
    viewerRootSaga,
    messagesCacheRootSaga,
    normalizedApiRootSaga,
    processAuthEvents,
    watchForbiddenErrors,
    slashCommandsRootSaga,
    slashCommandRequestRootSaga,
  ];

  const tasks = sagas.map((saga) => {
    return sagaMiddleware.run(saga, sagaContext);
  });

  stopSaga = () => {
    tasks.reverse().forEach((task) => {
      task.cancel();
    });
  };

  return {
    stopSaga,
    store,
  };
};
