import { useEffect } from 'react';
import { ClientApi } from '@serenityapp/api-client-graph';
import { Schema } from '@serenityapp/domain';
import { UseQueryArgs, useQuery } from 'urql';

type UseDeviceDetailReturnType = {
  device?:
    | ClientApi.AlexaDevice.Fragments.DetailType
    | ClientApi.DigitalSign.Fragments.DetailType;
  isDeviceStale: boolean;
  isDeviceDetailFetching: boolean;
};

/**
 * This hook is used to fetch the device detail by its ID.
 *
 * @param id
 */
const useDeviceDetail = (id: string): UseDeviceDetailReturnType => {
  const deviceGetQueryOptions: UseQueryArgs<
    Schema.Device.Get.Variables,
    ClientApi.Device.DeviceApi.DeviceGetResult
  > = {
    query: ClientApi.Device.DeviceApi.deviceGetQuery,
    requestPolicy: 'cache-and-network',
    variables: {
      input: {
        id,
      },
    },
  };

  const [queryResult, reexecuteQuery] = useQuery(deviceGetQueryOptions);

  useEffect(() => {
    // Reexecute the query every minute
    const pollingInterval = setInterval(() => {
      reexecuteQuery({ requestPolicy: 'cache-and-network' });
    }, 60000);

    // Cleanup the interval on component unmount
    return () => clearInterval(pollingInterval);
  }, [reexecuteQuery]);

  const { data, fetching: isDeviceDetailFetching, stale: isDeviceStale } = queryResult;
  const device = data && data.result;

  return {
    device,
    isDeviceStale,
    isDeviceDetailFetching,
  };
};

export default useDeviceDetail;
