import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import { useMutation } from 'urql';
import { useNavigate, useParams } from 'react-router-dom';

import { RouteParamId } from '@serenityapp/domain';
import { FilterCreateEditFormValues } from '@serenityapp/components-react-common';
import { useMakeTestId } from '@serenityapp/components-react-web';
import { ClientApi } from '@serenityapp/api-client-graph';
import { snackAdd } from '@serenityapp/redux-store';

import { FilterCreateEditForm } from './components';
import { useFilterDetail } from './hooks';

const FilterEditDrawer = () => {
  const makeTestId = useMakeTestId('FilterEditDrawer');
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { id } = useParams<RouteParamId>() as RouteParamId;

  const [, updateFilter] = useMutation<
    ClientApi.Filter.Api.Update.MutationResult,
    ClientApi.Filter.Api.Update.Variables
  >(ClientApi.Filter.Api.Update.Mutation);

  const { filter, isFilterDetailFetching, generalChannels, residentChannels } =
    useFilterDetail(id);

  const initialValues = {
    name: filter?.name ?? '',
    description: filter?.description ?? '',
    filterPositionPriority: filter?.filterPositionPriority ?? 0,
    generalChannels,
    residentChannels,
  };

  const goBack = () => navigate('..');

  const handleFormSubmit = (values: FilterCreateEditFormValues) => {
    setIsSubmitting(true);
    const selectedChannels = [
      ...(values.residentChannels || []),
      ...(values.generalChannels || []),
    ];

    const input = {
      id,
      name: values.name?.trim(),
      description: values.description?.trim(),
      filterPositionPriority: values.filterPositionPriority,
      conversations: R.pluck('id', selectedChannels),
    };

    updateFilter({
      input,
    }).then((result) => {
      setIsSubmitting(false);
      if (result.error || result.data?.result?.success === false) {
        dispatch(snackAdd({ message: 'Error updating filter', type: 'error' }));
      } else {
        dispatch(snackAdd({ message: 'Filter successfully updated', type: 'success' }));
        goBack();
      }
    });
  };

  return (
    <FilterCreateEditForm
      isEditMode
      dataTestId={makeTestId('')}
      filterId={filter?.id}
      filterName={filter?.name}
      handleFormClose={goBack}
      handleFormSubmit={handleFormSubmit}
      initialValues={initialValues}
      isLoading={isFilterDetailFetching}
      isSaving={isSubmitting}
      title={filter?.name ? `Edit filter - ${filter.name}` : 'Edit filter'}
    />
  );
};

export default FilterEditDrawer;
