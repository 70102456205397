import { GridRow, GridRowProps } from '@mui/x-data-grid-pro';

type CustomGridRowProps = GridRowProps & {
  makeTestId: (val: string) => string;
};

/**
 * This is a custom GridRow component that adds a data-testid attribute to the GridRow component from MUI DataGrid.
 */
// TODO: (LIZA) Move to a common place when refactoring other tables to DataGrid
const CustomGridRow = (props: CustomGridRowProps) => {
  const { makeTestId, ...rest } = props;
  return <GridRow {...rest} data-testid={makeTestId(`GridRow-${props.row.id}`)} />;
};

export default CustomGridRow;
