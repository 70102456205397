import { UseQueryArgs, useQuery } from 'urql';
import { useDispatch } from 'react-redux';

import { ClientApi } from '@serenityapp/api-client-graph';
import { snackAdd } from '@serenityapp/redux-store';
import { SelectorItemProps } from '@serenityapp/components-react-common';

import { filterChannelsBySubtype } from '../utils';

type UseFilterDetailReturnType = {
  filter?: ClientApi.Filter.Fragments.DetailType;
  isFilterDetailFetching: boolean;
  generalChannels: SelectorItemProps[];
  residentChannels: SelectorItemProps[];
};

/**
 * This hook is used to fetch the filter detail by its ID.
 *
 * @param id
 */
const useFilterDetail = (id: string): UseFilterDetailReturnType => {
  const dispatch = useDispatch();

  const options: UseQueryArgs<
    ClientApi.Filter.Api.Get.Variables,
    ClientApi.Filter.Api.Get.Result
  > = {
    query: ClientApi.Filter.Api.Get.Query,
    requestPolicy: 'cache-and-network',
    variables: {
      input: {
        id,
      },
    },
  };

  const [result] = useQuery(options);
  if (result.error) {
    dispatch(snackAdd({ message: `Error fetching filter details`, type: 'error' }));
  }

  const { data, fetching, stale } = result;

  const filter = data && data.viewer.filter;

  const channels = filter?.conversations?.edges?.filter((edge) => !edge.archived) || [];

  const generalChannels = channels && filterChannelsBySubtype(channels, 'general');

  const residentChannels = channels && filterChannelsBySubtype(channels, 'resident');

  return {
    filter,
    isFilterDetailFetching: fetching || stale,
    generalChannels: generalChannels || [],
    residentChannels: residentChannels || [],
  };
};

export default useFilterDetail;
