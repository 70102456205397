import { useMemo } from 'react';
import { useQuery, UseQueryArgs } from 'urql';
import { ClientApi } from '@serenityapp/api-client-graph';
import { useDispatch } from 'react-redux';
import { snackAdd } from '@serenityapp/redux-store';

type UseFiltersReturnType = {
  filters: ClientApi.Filter.Fragments.ListType[];
  isFetching: boolean;
};

/**
 * This hook is used to fetch the filters (formally locations) of the current viewer.
 */
const useFilters = (): UseFiltersReturnType => {
  const dispatch = useDispatch();

  const options: UseQueryArgs<
    ClientApi.Viewer.Api.Filters.Variables,
    ClientApi.Viewer.Api.Filters.Response
  > = {
    query: ClientApi.Viewer.Api.Filters.Query,
    requestPolicy: 'cache-and-network',
    variables: undefined,
  };

  const [result] = useQuery(options);

  if (result.error) {
    dispatch(snackAdd({ message: 'Error fetching filters', type: 'error' }));
  }

  const { edges = [] } = result?.data?.viewer?.filters ?? {};

  const filterUnarchived = (edges: Array<{ node: ClientApi.Filter.Fragments.ListType }>) => {
    return edges.reduce((acc, edge) => {
      const node = edge.node;
      if (!node.archived) {
        acc.push(node);
      }
      return acc;
    }, [] as ClientApi.Filter.Fragments.ListType[]);
  };

  const sortByName = (filters: ClientApi.Filter.Fragments.ListType[]) => {
    return [...filters].sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
  };

  const filters = useMemo(() => {
    return sortByName(filterUnarchived(edges));
  }, [edges]);

  return {
    filters,
    isFetching: result.fetching,
  };
};

export default useFilters;
